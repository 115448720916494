/* body {
  max-width: 100vh;
  
}


 .projectsDiv {
  background-color: #00adb5;
  display: flex;
  flex-direction: column;
  margin-top: 10vh;
}
.headerProjects {
  text-align: center;
  margin-bottom: 15vh;
  color: #eeeeee;
  font-size: 35px;
  margin-top: 5vh;
}

.projectHeader {
  font-size: 20px;
  margin-top: 30px;
  color: #eeeeee;
  margin-bottom: 15px;
}

.projectWrapper {
  height: auto;
  width: 100vh;
  margin-left: 15%;

}
.flexBox {
  display: flex;
}

.bottomMargin {
  height: 10vh;
}



.each-fade {
  display: flex;
  height: auto;
  width:75%;
  
}

.each-fade > div {
  width:80%;
}

.each-fade > div img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.each-fade p {
  width: 25%;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #393e46;
}

.sliderText {
  line-height: 1.42;
  color: #fff;
}

.slideWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  align-content: flex-start;

  
}

.footerSlogan {
  font-style:oblique;
  font-size:10px;
  margin-left: 30%;
} */

@media  (max-width:767px) {
  .projectsDiv {
    display: none;
  }
}

@media only screen and (min-width:768px) {

  .projectsDiv {
    background-color: #00adb5;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
  }
  .headerProjects {
    text-align: center;
    margin-bottom: 15vh;
    color: #eeeeee;
    font-size: 35px;
    margin-top: 5vh;
  }
  
  .projectHeader {
    font-size: 30px;
    margin-top: 30px;
    color: #eeeeee;
    margin-bottom: 15px;
  }
  
  .projectWrapper {
    height: auto;
    width: 100vh;
    margin-left: 30%;
  
  }
  .flexBox {
    display: flex;
  }
  
  .bottomMargin {
    height: 10vh;
  }
  
  
  
  .each-fade {
    display: flex;
    height: auto;
    width: 100%;
  }
  
  .each-fade > div {
    width:80%;
  }
  
  .each-fade > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .each-fade p {
    width: 25%;
    font-size: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #393e46;
  }
  
  .sliderText {
    line-height: 1.42;
    color: #fff;
  }
  
  .slideWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    align-content: flex-start;
    
  }
}