#project {
  background-color: #00adb5 ;
}

.projects__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 110vh;
}

.project__img {
  height: 150px;
  width: auto;
  
}

.projectDiv > h3 {
  margin-bottom: 5vh;
  color: #eeeeee;
  font-size: 20px;
  margin-top: 5vh;
}

.project__header {
  text-align: center;
  padding-top: 35px ;
  color: #eeeeee;
  font-size: 35px;
  background-color: #00adb5;
  
}

.footer__slogan {
  font-style:oblique;
  font-size:10px;
  margin-left: 30%
}


@media only screen and (min-width:768px) {
  #project {
    display: none;
  }
}