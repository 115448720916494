

.timeLineWrapper {
  margin-top: 20vh;
}
.workList {
  margin-left: 10%;
}

.vertical-timeline-element--work {
  width: 80%
}

.vertical-timeline-element--education {
  width: 80%;
}


@media only screen and (min-width:768px) {
  
  .workList {
    margin-left: 30px;
  }
}
