#profile {
  height: 130vh;
  margin-bottom: 30vh;
}
.profileSectionHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}
.profilSectionWrapper {
  height: 250px;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
}

.header {
  font-size: 35px;
  color: #222831;
  margin-top: 10vh;
}
.sectionProfileHr {
  width: 25%;
  height: 1px;
  background-color: black;
}

.fontStyle {
  color: #222831;
}
.slogan {
  font-style: italic;
  font-size: 15px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.socialLink {
  margin-left: 10px;
}

.profilePicture {
  width: auto;
  height: 200px;
  margin: 50px;
}

.aboutMeDiv {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  margin-top: 7vh;
}
.aboutMeArticle {
  text-align: left;
  line-height: 1.42;
}

.details {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.125s;
  width: 288px;
  height: auto;
  background-color: #00adb5;
  color: #ffffff;
  padding: 20px;
  border-radius: 10px;
}

.details:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 768px) {
  #profile {
    height: 70vh;
  }
  .profileSectionHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .profilSectionWrapper {
    height: 600px;
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
  }

  .header {
    font-size: 35px;
    color: #222831;
  }
  .sectionProfileHr {
    width: 25%;
    height: 1px;
    background-color: black;
  }
  .fontStyle {
    color: #222831;
  }
  .slogan {
    font-style: italic;
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .socialLink {
    margin-left: 10px;
  }

  .profilePicture {
    width: auto;
    margin: 50px;
  }

  .aboutMeDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
    margin-left: 3%;
  }
  .aboutMeArticle {
    width: 300px;
    text-align: left;
    line-height: 1.42;
  }

  .details {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.125s;
    width: 288px;
    height: auto;
    background-color: #00adb5;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
  }

  .details:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}
